<template>
	<div>
		<div class="card py-5">
			<div class="card-body py-5">
				<div class="row align-items-center">
					<div class="col-lg-7 text-center">
						<h1 class="font-50">{{ $t('admInicio.bemVindo1') }}<span class="color-theme d-block">World Fivem</span></h1>
						<p class="font-20">{{ $t('admInicio.bemVindo2') }}</p>
						<p class="font-16">{{ $t('admInicio.bemVindo3') }}<br>{{ $t('admInicio.bemVindo4') }}</p>
						<button @click="setRouteCliente" class="btn btn-primary font-16"><i class="far fa-arrow-alt-right font-14 me-1"></i> {{ $t('admInicio.bemVindoBotao') }}</button>
					</div>
					<div class="col-lg-5 text-center">
						<img class="wpx-300" src="@/assets/admin/images/Logo.png" alt="Start image">
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-2 mb-2">

		<!-- atalhos -->
		<div class="cards-1">
			<div class="card mb-0 cursor-pointer" @click="abrirAtalho('admPerfil', null)">
				<div class="card-body text-center">
					<img src="@/assets/admin/images/dashboard/experiencia-de-usuario.png" class="mb-4 mt-3" width="80" />
					<h1 class="font-18">{{ $t('admInicio.atalhoPerfil') }}</h1>
					<p class="mb-0">{{ $t('admInicio.atalhoPerfilDesc') }}</p>
				</div>
			</div>
			<div class="card mb-0 cursor-pointer" @click="abrirAtalho('admMeusCursos', null)">
				<div class="card-body text-center">
					<img src="@/assets/admin/images/dashboard/aula.png" class="mb-4 mt-4" width="80" />
					<h1 class="font-18">{{ $t('admInicio.atalhoCursos') }}</h1>
					<p class="mb-0">{{ $t('admInicio.atalhoCursosDesc') }}</p>
				</div>
			</div>
			<div class="card mb-0 cursor-pointer" @click="abrirAtalho('admMeusScripts', null)">
				<div class="card-body text-center">
					<img src="@/assets/admin/images/dashboard/codificacao.png" class="mb-4 mt-3" width="80" />
					<h1 class="font-18">{{ $t('admInicio.atalhoScripts') }}</h1>
					<p class="mb-0">{{ $t('admInicio.atalhoScriptsDesc') }}</p>
				</div>
			</div>
			<div class="card mb-0 cursor-pointer" @click="abrirAtalho('admCompras', null)">
				<div class="card-body text-center">
					<img src="@/assets/admin/images/dashboard/gerenciamento-de-projetos.png" class="mb-4 mt-3" width="80" />
					<h1 class="font-18">{{ $t('admInicio.atalhoHistorico') }}</h1>
					<p class="mb-0">{{ $t('admInicio.atalhoHistoricoDesc') }}</p>
				</div>
			</div>
			<!-- <div class="card mb-0 cursor-pointer" @click="abrirAtalho('admDiscord', null)">
				<div class="card-body text-center">
					<img src="@/assets/admin/images/dashboard/discordia.png" class="mb-4 mt-3" width="80" />
					<h1 class="font-18">Discord</h1>
					<p class="mb-0">Fique por dentro das novidades pelo discord.</p>
				</div>
			</div> -->
		</div>
		<!-- <hr class="mt-2 mb-2"> -->

		<!-- <div class="card mb-2">
			<div class="tp-hero__area pt-100 pb-80 p-relative p-5">
				<div class="container">
					<div class="row align-items-start">
						<div class="row align-items-center">
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__section-box">
									<h3 class="tp-hero__title-lg pb-20 color-theme">{{ $t("dashboard.landingCards.welcome.title") }}</h3>
									<div class="tp-about__text">
										<p class="aboutContent">
                                 {{ $t("dashboard.landingCards.welcome.desc") }}
										</p>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/admin/images/dashboard/desck-banner-3.png" alt="about us">
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-2">
			<div class="tp-hero__area pt-100 pb-80 p-relative p-5">
				<div class="container">
					<div class="row align-items-start">
						<div class="row align-items-center">
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/admin/images/dashboard/desck-banner-1.png" alt="about us">
								</div>
								</div>
							</div>
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__section-box">
									<h3 class="tp-hero__title-lg pb-20 color-theme">{{ $t("dashboard.landingCards.ticket.title") }}</h3>
									<div class="tp-about__text">
										<p class="aboutContent">
                                 {{ $t("dashboard.landingCards.ticket.desc") }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-2">
			<div class="tp-hero__area pt-100 pb-80 p-relative p-5">
				<div class="container">
					<div class="row align-items-start">
						<div class="row align-items-center">
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__section-box">
									<h3 class="tp-hero__title-lg pb-20 color-theme">{{ $t("dashboard.landingCards.wl.title") }}</h3>
									<div class="tp-about__text">
										<p class="aboutContent">
                                 {{ $t("dashboard.landingCards.wl.desc") }}
										</p>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-lg-6">
								<div class="tp-about__thumb-wrapper p-relative">
								<div class="tp-about__thumb">
									<img class="w-100" src="@/assets/admin/images/dashboard/desck-banner-2.png" alt="about us">
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<!-- banner discord
		<div class="card mb-2 cursor-pointer" @click="irDiscord">
			<div class="row">
				<img class="rounded w-100" src="@/assets/admin/images/deck-appa-page-banner.png" alt="bannerDiscord">
			</div>
		</div> -->
	</div>
</template>

<script>

import router from '@/router'

export default {
	name: 'Inicio',
	methods: {
		irDiscord : function () {
			window.open('https://discord.gg/BhyRdt36Vf', '_blank');
		},
		abrirAtalho : function (atalho, tag) {
         if (tag == null) {
				this.$router.push('/' + atalho);
			} else {
				this.$router.push({ path: '/' + atalho, query: { tag: tag } });
			}
		},
		setRouteCliente : function () {
         router.push('/')
      }
	}
}

</script>

<style scoped>

img[alt="bannerDiscord"] {
	filter: brightness(80%) grayscale(100%) invert(100%);
}

html.success-theme img[alt="bannerDiscord"],
html.primary-theme img[alt="bannerDiscord"],
html.dark-theme img[alt="bannerDiscord"],
html.danger-theme img[alt="bannerDiscord"] {
	filter: none
}

</style>